<template>
  <div>
    <div class="text-right mb-1">
      <b-button
        :to="{name:'azkar-add'}"
        variant="primary"
      >
        Add Zikr
      </b-button>
    </div>

    <b-card header="Search">
      <b-form-row>
        <b-col
          md="6"
        >
          <b-form-group
            label="Content"
            label-for="search"
          >
            <search
              placeholder="Title"
              :search-query.sync="query.search"
              @refresh="$refs.table.refresh()"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <azkar-type-select
            :type.sync="queryTypeProxy"
            @refresh="$refs.table.refresh()"
          />
        </b-col>
        <b-col
          md="6"
        >
          <azkar-category-select
            ref="category-select"
            :category.sync="query.category"
            :filter="{type:query.type.value}"
            @refresh="$refs.table.refresh()"
          />
        </b-col>
        <b-col
          md="2"
          class="mt-2 ml-auto"
        >
          <search-button
            @refresh="$refs.table.refresh()"
          />
        </b-col>
      </b-form-row>
    </b-card>
    <b-card>
      <b-table
        ref="table"
        :items="getAzkar"
        :fields="fields"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        responsive
        show-empty
      >
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item :to="{name:'azkar-view',params:{id:item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('update','global-AzkarController')"
              :to="{name:'azkar-edit',params:{id:item.id} }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('delete','global-AzkarController')"
              @click="deleteItem(item)"
            >
              <div class="text-danger">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Delete</span>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import Azkar from '@/common/compositions/azkar/AzkarApi'
import paginationData from '@/common/compositions/common/paginationData'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import Search from '@/common/components/common/Table/Search.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import AzkarTypeSelect from '@/common/components/azkar/AzkarTypeSelect.vue'
import AzkarCategorySelect from '@/common/components/azkar/AzkarCategorySelect.vue'

export default {
  name: 'AzkarList',
  components: {
    AzkarTypeSelect,
    Pagination,
    Search,
    SearchButton,
    AzkarCategorySelect,
  },
  data() {
    return {
      query: { type: { name: 'All', value: null } },
    }
  },
  computed: {
    queryTypeProxy: {
      get() {
        return this.query.type
      },
      set(value) {
        this.$set(this.query, 'type', value)
        this.$set(this.query, 'category', null)
        this.$nextTick(() => {
          this.$refs['category-select'].getazkarOptions()
        })
      },
    },
    fields() {
      return [
        'content_en',
        'content_ar',
        { key: 'category', formatter: v => v?.name_en },
        'count',
        'actions',
      ]
    },
    formattedQuery() {
      return {
        search: this.query.search,
        type: this.query.type.value,
        categoryId: this.query.category?.id,
      }
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { confirmOperation, successfulDeletionAlert } = handleAlerts()
    const { listAzkarRequest, deleteAzkarRequest } = Azkar()
    return {
      pagination, listAzkarRequest, confirmOperation, successfulDeletionAlert, deleteAzkarRequest,
    }
  },
  methods: {
    getAzkar() {
      return this.listAzkarRequest({ ...this.formattedQuery, page: this.pagination.currentPage }).then(res => {
        this.pagination.totalRows = res.data.data.pagination.total
        return res.data.data.data || []
      })
    },
    deleteItem(item) {
      this.confirmOperation('Are you sure you want to delete this zikr').then(() => {
        this.deleteAzkarRequest(item.id).then(() => {
          this.successfulDeletionAlert('Zikr is deleted successfully')
          this.$refs.table.refresh()
        })
      })
    },
  },
}
</script>
<style lang="">

</style>
